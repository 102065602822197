<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img fluid :src="imgUrl" />
          <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
          </h2> -->
        </b-link>

        <b-card-title class="mb-1"> ยินดีต้อนรับเข้าสู่ระบบ </b-card-title>
        <b-card-text class="mb-2"> กรุณาลงชื่อเข้าใช้บัญชีของคุณ </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <b-form-group label="ชื่อผู้ใช้งาน" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false : null"
                  name="username"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">รหัสผ่าน</label>
                <!-- <b-link :to="{name:'auth-forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                จดจำฉัน
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <p v-if="isError" style="color: red;">{{ errorMsg }}</p>
            <b-button variant="primary" type="submit" block :disabled="invalid">
              Sign in
            </b-button>
            <!-- <b-button variant="primary" type="button" block @click="SignIn">
              Sign in New
            </b-button> -->
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { ref, onUnmounted } from "@vue/composition-api";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import authenStoreModule from "./authenStoreModule";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      // password: 'admin',
      // username: 'admin@demo.com',
      username: "",
      password: "",
      sideImg: require("@/assets/images/logo/logote.png"),
      // validation rules
      required,
      email,
      isError: false,
      errorMsg: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg;
    },
  },
  setup() {
    // Register module
    if (!store.hasModule("authen"))
      store.registerModule("authen", authenStoreModule);

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule("authen")) store.unregisterModule("authen");
    // });
  },

  methods: {
    loginBypass() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: "admin@demo.com",
              password: "admin",
            })
            .then((response) => {
              const { userData } = response.data;
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem("userData", JSON.stringify(userData));
              this.$ability.update(userData.ability);

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit(
                "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
                userData.extras.eCommerceCartItemsCount
              );

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.push({ name: "dashboardadmin-ecommerce" });
            })
            .catch((error) => {
              this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          const userModel = {
            userName: this.username,
            password: (this.password),
          };
          store
            .dispatch("authen/login", userModel)
            .then((response) => {
              if (response.status == 200) {
                const userData = {
                  userId: response.data.userId || 0,
                  userCode: response.data.userCode || 0,
                  roleId: response.data.roleId || 0,
                  username: response.data.username || "",
                  fullName: response.data.fullname || "",

                  email: response.data.email || "",

                  token: response.data.token || "",

                  role: response.data.roleName || "",
                  ability: [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ],
                };
                if (userData.role == "admin") {
                  useJwt.setToken(userData.token);

                  localStorage.setItem("userData", JSON.stringify(userData));

                  this.$ability.update(userData.ability);

                  this.$router.push({ name: "dashboardadmin-ecommerce" });
                } else {
                  this.isError = true;
                  this.errorMsg = "ไม่มีสิทธิ์เข้าใช้งาน";
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Login failed",
                    icon: "CoffeeIcon",
                    variant: "error",
                    text: `${response.data.statusCode}: ${response.data.message}`,
                  },
                });
              }
            })
            .catch((error) => {
              this.isError = true;
              this.errorMsg = "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง";
              // this.showToast('danger', 'XCircleIcon', error.response.data)
              this.$refs.loginForm.setErrors(error.response.error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
