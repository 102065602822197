<template>
  <!-- Table Container Card -->
  <div>
    <div class="table-list">
      <div style="display: flex; justify-content: space-between" class="mb-1">
        <b-breadcrumb class="breadcrumb-slash mb-1">
          <b-breadcrumb-item :to="{ name: 'dashboardadmin-ecommerce' }">
            จัดการคำสั่งซื้อ
            <!-- Dashboard -->
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            รายการเปิดบิล
            <!-- ข้อมูลคำสั่งซื้อ -->
          </b-breadcrumb-item>
        </b-breadcrumb>
        <div>
          <b-link :to="{ name: 'admin-openbill' }">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="mr-1"
            >
              เปิดบิล
            </b-button>
          </b-link>

          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="modalOrderlistClick"
            variant="primary"
          >
            รายการสั่งพิมพ์
          </b-button> -->
        </div>
      </div>

      <b-card no-body>
        <div class="m-2">
          <b-row class="mb-1">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="ค้นหาทั้งหมด… (หมายเลข, ชื่อ, เบอร์)"
              />
            </b-col>
            <b-col cols="12" md="4">
              <v-select
                v-model="searchUser"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="optionsUser"
                class="invoice-filter-select"
                placeholder="ทั้งหมด"
                multiple
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
          </b-row>
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>แสดง</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
              <label> รายการ </label>
              <!-- <b-button variant="primary" :to="{ name: 'apps-invoice-add' }">
                Add Record
              </b-button> -->
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="9">
              <b-row>
                <b-col cols="4">
                  <v-select
                    v-model="statusFilter"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    placeholder="All"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col cols="4">

                  <div class="d-flex">
                    <flat-pickr
                      v-model="date"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                        time_24hr: true,
                      }"
                      placeholder="วันที่เริ่มต้น"
                    />
                    <b-button v-ripple.400="'rgba(113, 133, 133, 0.15)'" @click="date=null">
                      X
                    </b-button>
                  </div>


                </b-col>
                <b-col cols="4">
                 

                  <div class="d-flex">
                    <flat-pickr
                    v-model="dateTo"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                    }"
                    placeholder="วันที่สิ้นสุด"
                  />
                    <b-button v-ripple.400="'rgba(113, 133, 133, 0.15)'" @click="dateTo=null">
                      X
                    </b-button>
                  </div>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refInvoiceListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="ไม่พบข้อมูล"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #head(invoiceStatus)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>

          <!-- Column: Id -->
          <template #cell(orderNo)="data">
            <b-link
              :to="{
                name: 'admin-openbillpreview',
                params: { id: data.item.orderId },
              }"
              class="font-weight-bold"
            >
              #{{ data.item.orderNo }}
            </b-link>
          </template>
          <template #cell(index)="data">
            {{ (currentPage - 1) * perPage + data.index + 1 }}
          </template>

          <!-- Column: Invoice Status -->
          <template #cell(invoiceStatus)="data">
            <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${
                resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                  .variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                    .icon
                "
              />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
              <p class="mb-0">
                {{ data.item.invoiceStatus }}
              </p>
              <p class="mb-0">Balance: {{ data.item.balance }}</p>
              <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
            </b-tooltip>
          </template>

          <!-- Column: Client -->
          <template #cell(client)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.client.name)"
                  :variant="`light-${resolveClientAvatarVariant(
                    data.item.invoiceStatus
                  )}`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.client.name }}
              </span>
              <small class="text-muted">{{
                data.item.client.companyEmail
              }}</small>
            </b-media>
          </template>

          <!-- Column: Issued Date -->
          <template #cell(issuedDate)="data">
            <span class="text-nowrap">
              {{ data.value }}
            </span>
          </template>

          <!-- Column: Balance -->
          <template #cell(balance)="data">
            <template v-if="data.value === 0">
              <b-badge pill variant="light-success"> Paid </b-badge>
            </template>
            <template v-else>
              {{ data.value }}
            </template>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!-- <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="SendIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Send Invoice"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              /> -->

              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="
                  $router.push({
                    name: 'admin-openbillpreview',
                    params: { id: data.item.orderId },
                  })
                "
              />
              <!-- <b-tooltip
                title="Preview Invoice"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              /> -->

              <!-- Dropdown -->
              <!-- <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Download</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'apps-invoice-edit',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">Duplicate</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                แสดง
                {{ dataMeta.to }}
                จาก
                {{ dataMeta.of }}
                รายการ
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalInvoices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-orderlist"
        centered
        title="รายการสั่งพิมพ์"
        ok-only
        ok-title="ตกลง"
        size="xl"
        @ok="handleModalOk"
        class="model-table"
      >
        <b-table
          ref="refInvoiceListTable"
          :items="fetchInvoices"
          responsive
          :fields="tableColumnsPrint"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="ไม่พบข้อมูล"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative model-table"
        >
          <template #head(radio)>
            <b-form-checkbox @input="selectedAll"> </b-form-checkbox>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- Column: Radio -->
          <template #cell(radio)="data">
            <b-form-checkbox v-model="selectedPrint" :value="data.item">
            </b-form-checkbox>
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <b-link
              :to="{
                name: 'apps-invoice-preview',
                params: { id: data.item.id },
              }"
              class="font-weight-bold"
            >
              #{{ data.value }}
            </b-link>
          </template>

          <!-- Column: Invoice Status -->
          <template #cell(invoiceStatus)="data">
            <b-avatar
              :id="`invoice-row-${data.item.id}`"
              size="32"
              :variant="`light-${
                resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                  .variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveInvoiceStatusVariantAndIcon(data.item.invoiceStatus)
                    .icon
                "
              />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
              <p class="mb-0">
                {{ data.item.invoiceStatus }}
              </p>
              <p class="mb-0">Balance: {{ data.item.balance }}</p>
              <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
            </b-tooltip>
          </template>

          <!-- Column: Client -->
          <template #cell(client)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.client.name)"
                  :variant="`light-${resolveClientAvatarVariant(
                    data.item.invoiceStatus
                  )}`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.client.name }}
              </span>
              <small class="text-muted">{{
                data.item.client.companyEmail
              }}</small>
            </b-media>
          </template>

          <!-- Column: Issued Date -->
          <template #cell(issuedDate)="data">
            <span class="text-nowrap">
              {{ data.value }}
            </span>
          </template>

          <!-- Column: Balance -->
          <template #cell(balance)="data">
            <template v-if="data.value === 0">
              <b-badge pill variant="light-success"> Paid </b-badge>
            </template>
            <template v-else>
              {{ data.value }}
            </template>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="SendIcon"
                class="cursor-pointer"
                size="16"
              />
              <b-tooltip
                title="Send Invoice"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />

              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="
                  $router.push({
                    name: 'apps-invoice-preview',
                    params: { id: data.item.id },
                  })
                "
              />
              <b-tooltip
                title="Preview Invoice"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />

              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Download</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'apps-invoice-edit',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">Duplicate</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-modal>
    </div>
    <div class="print-data" v-show="false">
      <b-row>
        <b-col
          cols="4"
          class="border p-2"
          v-for="(item, index) in dataApiB"
          :key="index"
          style="height: 35.2rem; display: flex; align-items: center"
        >
          <div style="display: block; font-size: 1.5rem">
            <div>{{ item.userFullName }}</div>
            <div>{{ item.userFullName }} {{ item.userPhoneNumber }}</div>

            <div class="d-flex" style="flex-wrap: wrap;">
              <div
                v-for="(i, index) in item.orderLists"
                :key="index"
                class="mr-1"
          
              >
                <b :style="{ color: i.productColor }"
                  >{{ i.productCode }} / {{ i.qty }}</b
                >
              </div>

              <div v-if="item.orderPayment == 2">
                {{
                  item.orderPayment == 2
                    ? item.totalPrice.toLocaleString("en-US")
                    : ""
                }}
              </div>
              <div>{{ changeTextP(item.orderPayment) }}</div>
            </div>

            <div>Order#{{ item.orderNo }}</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
  BModal,
  VBModal,
  BFormCheckbox,
  BFormTimepicker,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "../invoiceStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BBreadcrumb,
    BBreadcrumbItem,
    vSelect,
    BModal,
    VBModal,
    ToastificationContent,
    BFormCheckbox,
    flatPickr,
    BFormTimepicker,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "openbill-list";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { value: null, label: "ทั้งหมด" },
      { value: 0, label: "ยกเลิก " },
      { value: 1, label: "รับคำสั่งซื้อ" },
      { value: 2, label: "รอชำระ" },
      { value: 3, label: "ยืนยันคำสั่งซื้อ" },
      { value: 4, label: "กำลังจัดส่ง" },
      { value: 5, label: "จัดส่งแล้ว" },
    ];

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      tableColumnsPrint,
      date,
      dataApiB,
      changeTextP,
      searchUser,
      searchTime,
      dateTo,
    } = useInvoicesList();
    const selectedPrint = ref([]);
    const printInvoice = () => {
      window.print();
      selectedPrint.value = [];
    };

    const optionsUser = ref([]);
    const fetchProductCategorys = () => {
      store.dispatch("openbill-list/fetchUserList").then((response) => {
        if (response !== null) {
          response.data.data.forEach((data) => {
            optionsUser.value.push({
              label: data.username,
              value: data.userId,
              userCode: data.userCode,
            });
          });
        }
      });
    };

    fetchProductCategorys();

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      tableColumnsPrint,
      printInvoice,
      date,
      dataApiB,
      selectedPrint,
      changeTextP,
      searchUser,
      optionsUser,
      searchTime,
      dateTo,
    };
  },

  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    handleModalOk() {
      if (this.selectedPrint.length > 0) {
        this.printInvoice();
        // this.showToast("success", "CheckIcon", "Your Workhas been saved");
      }
      this.$emit("close");
    },
    async modalOrderlistClick() {
      await this.dataApiB.forEach((item) => {
        this.selectedPrint.push({
          ...item,
        });
      });
      this.printInvoice();
    },
    selectedAll(e) {
      if (e) {
        this.dataApiB.forEach((item) => {
          this.selectedPrint.push({
            ...item,
          });
        });
      } else {
        this.selectedPrint = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

@media print {
  // Global Styles
  .print-data {
    display: block !important;
  }

  .table-list {
    display: none;
  }

  .model-table {
    display: none;
  }

  .modal-header {
    display: none;
  }

  .modal-footer {
    display: none;
  }

  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<style lang="scss"></style>
