import axios, { baseURL } from "@axios";
import { paginateArray, sortCompare } from "@/common";
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSubinventorys(ctx) {
      const userData = getUserData();
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${baseURL}/api/SubInventory/GetDapperByUserByRole?RoleId=${userData.roleId}&UserId=${userData.userId}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInventory(ctx) {
      const userData = getUserData();
      const inven = "คลังตัวแทน";
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Inventory/GetByKey?key=${inven}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContact(ctx, { searchQuery }) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Contact/GetByKey?key=${searchQuery}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchZipcode(ctx, { zipcode }) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Zone/GetByKey?zipcode=${zipcode}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContactAddress(ctx, { searchQuery }) {
      const userData = getUserData();

      const payload = {
        key: searchQuery,
        dateFrom: null,
        dateTo: null,
        status: null,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/ContactAddress/GetByKey`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    handlerConfirm(ctx, product) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/AddOrUpdate`, product, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    handlerConfirmNew(ctx, product) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/Add`, product, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    handlerAddContact(ctx, product) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/ContactAddress/AddOrUpdate`, product, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    handlerOpenbill(ctx, product) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/AddList`, product, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addOrder(ctx, payload) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/order/Add`, payload, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addCartProduct(ctx, payload) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/Add`, payload, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCartProduct(ctx, payload) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/Update`, payload, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteCartProduct(ctx, payload) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/Delete`, payload, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCartPro(ctx, { id }) {
      const userData = getUserData();

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Cart/GetCartList?userId=${id}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAddressByKey(ctx, { key }) {
      const userData = getUserData();
      console.log("key", key);
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Zone/GetAddressByKey?key=${key}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
