<template>
  <div>
    <div style="display: flex; justify-content: space-between" class="mb-1">
      <b-breadcrumb class="breadcrumb-slash mb-1">
        <b-breadcrumb-item :to="{ name: 'history-order' }">
          รายการคำสั่งซื้อ
          <!-- ข้อมูลคำสั่งซื้อ -->
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          รายละเอียด
          <!-- ข้อมูลคำสั่งซื้อ -->
        </b-breadcrumb-item>
      </b-breadcrumb>
      <!-- <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="printInvoice"
        >
          Print
        </b-button>
      </div> -->
    </div>

    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <section class="invoice-preview-wrapper">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <!-- <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    Vuexy
                  </h3>
                </div> -->
                  <div class="d-flex">
                    <h4>ตัวแทน : </h4>
                      <span class="card-text mb-25 ml-1">
                        {{ orderData.userName }} {{ orderData.userPhoneNumber }}
                      </span>
                  </div>
                  
                  <div class="d-flex">
                    <h5>ชื่อ - นามสกุล : </h5>
                    <p class="card-text mb-25 ml-1">
                      {{ orderData.userFullName }}
                    </p>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2 pl-5">
                  <h4 class="invoice-title">
                    หมายเลขคำสั่งซื้อ :
                    <span class="invoice-number">#{{ orderData.orderNo }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">วันที่สั่งซื้อ :</p>
                    <p class="invoice-date">
                      {{ orderData.createdDateText }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">สถานะคำสั่งซื้อ :</p>
                    <p class="invoice-date">
                      {{ changeText(orderData.orderStatus) }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" class="p-0 d-flex justify-content-between">
                  <div>
                    <h4>ลูกค้า :</h4>
                    <p class="card-text mb-25">
                      {{ orderData.contactName }} {{ orderData.contactMobile }}
                    </p>
                    <h5>รายละเอียดที่อยู่</h5>
                    <p class="card-text mb-25">
                      {{ orderData.contactAddress }}
                    </p>
                  </div>
                  <div class="mt-md-0 mt-2">
                    <h4>รายละเอียดการชำระเงิน</h4>
                    <div class="invoice-date-wrapper">
                    
                        {{ changeTextPayment(orderData.orderPayment) }}
                   
                    </div>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-card-body>
              <h4>รายการสินค้า</h4>
            </b-card-body>
            <b-table-lite
              responsive
              :items="orderData.orderLists"
              :fields="tableColumns"
            >
              <!-- <template #cell(taskDescription)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template> -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper" style="max-width: 100%">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">ส่วนลดคูปอง:</p>
                      <p class="invoice-total-amount">{{ (orderData.totalPrice - orderData.totalPriceStd).toLocaleString("en-US") }} บาท</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">ยอดคำสั่งซื้อรวม:</p>
                      <p class="invoice-total-amount">
                        {{ orderData.totalPriceStd.toLocaleString("en-US") }}
                        บาท
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">ยอดคำสั่งซื้อทั้งหมด :</p>
                      <p class="invoice-total-amount">
                        {{ orderData.totalPrice.toLocaleString("en-US") }}
                        บาท
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
          </b-card>
        </section>
      </b-col>
      <b-col cols="12" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->

          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            @click="printInvoice"
            v-if="orderData.orderStatus !== 0"
            
          >
            ปริ้น 
          </b-button> -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="danger"
            block
            v-b-modal.modal-confirmcanceln
            
            v-if="!orderData.isPrinted"
          >
            ยกเลิกออเดอร์
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <div style="display: flex; justify-content: end" class="mb-1">
      <b-link :to="{ name: 'history-order' }">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
          กลับ
        </b-button>
      </b-link>
    </div>
    <b-modal
      id="modal-confirmcanceln"
      centered
      title="ยกเลิกรายการ"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="confirmCancel"
    >
      <h4 style="display: flex; justify-content: center">
        ยืนยันยกเลิกรายการคำสั่งซื้อ
      </h4>
    </b-modal>
  </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BBreadcrumb,
  BBreadcrumbItem,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarSendInvoice from "../InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "../InvoiceSidebarAddPayment.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    BModal,
  },
  methods: {
    confirmCancel() {
      store
        .dispatch("order-list/updateOrder", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push({ name: "history-order" });
          }
          // invoiceData.value = response.data.invoice;
          // paymentDetails.value = response.data.paymentDetails;
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    },
  },
  setup() {
    const invoiceData = ref(null);
    const paymentDetails = ref({});

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: "Native App Development",
        taskDescription:
          "Developed a full stack native app using React Native, Bootstrap & Python",
        rate: "$60.00",
        hours: "30",
        total: "$1,800.00",
      },
      {
        taskTitle: "UI Kit Design",
        taskDescription:
          "Designed a UI kit for native app using Sketch, Figma & Adobe XD",
        rate: "$60.00",
        hours: "20",
        total: "$1200.00",
      },
    ];

    const INVOICE_APP_STORE_MODULE_NAME = "order-list";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const orderData = ref({});

    store
      .dispatch("order-list/fetchOrder", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        // invoiceData.value = response.data.invoice;
        // paymentDetails.value = response.data.paymentDetails;
        orderData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          invoiceData.value = undefined;
        }
      });

    const printInvoice = () => {
      window.print();
    };

    const tableColumns = [
      { key: "index", label: "ลำดับ" },
      { key: "productCode", label: "รหัสสินค้า" },
      { key: "productName", label: "ชื่อสินค้า" },
      {
        key: "price",
        label: "ราคาต่อชิ้น (บาท)",
        formatter: (val) => `${val.toLocaleString("en-US")}`,
      },
      {
        key: "qty",
        label: "จำนวน",
        formatter: (val) => `${val.toLocaleString("en-US")}`,
      },
      {
        key: "totalPrice",
        label: "ยอดคำสั่งซื้อ (บาท)",
        formatter: (val) => `${val.toLocaleString("en-US")}`,
      },
    ];
    const changeText = (val) => {
      let text = "";
      switch (val) {
        case 0:
          text = "ยกเลิก ";
          break;
        case 1:
          text = "รับคำสั่งซื้อ";
          break;
        case 2:
          text = "รอชำระ";
          break;
        case 3:
          text = "ยืนยันคำสั่งซื้อ";
          break;
        case 4:
          text = "กำลังจัดส่ง";
          break;
        case 5:
          text = "จัดส่งแล้ว";
          break;
        default:
          text = "รับคำสั่งซื้อ";
      }
      return text;
    };

    const changeTextPayment = (val) => {
      let text = "";
      switch (val) {
        case 0:
          text = "ยกเลิก ";
          break;
        case 1:
          text = "โอนเงิน";
          break;
        case 2:
          text = "เก็บเงินปลายทาง";
          break;
        case 3:
          text = "บัตรเครดิต";
          break;
        case 4:
          text = "กำลังจัดส่ง";
          break;
        case 5:
          text = "จัดส่งแล้ว";
          break;
        default:
          text = "รับคำสั่งซื้อ";
      }
      return text;
    };

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      orderData,
      tableColumns,
      changeText,
      changeTextPayment,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
