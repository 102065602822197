import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {
   
  },
  getters: {},
  mutations: {},
  actions: {
    // new
    fetchMyProducts(ctx,  queryParams) {
      console.log(" queryParams = ", queryParams);
      const userData = getUserData()
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        productId
      } = queryParams
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/SubInventory/GetSubInventoryByProduct?key=${productId}`,  {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response =>{
           console.log("response GetSubInventoryByProduct = ", response);
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.data.length,
            })
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
      },
    fetchSubinventorys(ctx, queryParams) {
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
      } = queryParams
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/SubInventory/GetSubInventoryByProductGroup?key=${queryParams.q}`)
          .then(response =>  {
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.totalCount,
            })
          })
          .catch(error => reject(error))
      })
    },
    fetchInventorys(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Inventory/GetByKey`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubinventory(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Subinventory/GetById`, { 
            params: {
              SubInventoryId: id
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSubinventory(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Subinventory/AddOrUpdate`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx) {
      const userData = getUserData()
      const payload = {
        key: "",
        dateFrom: null,
        dateTo: null,
        status: null
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/GetByKey`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetById`, { 
            params: {
              ProductId: id
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContacts(ctx) {
      const userData = getUserData()
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Contact/GetByKey`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/User/GetByKey`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Order/GetById`, { 
            params: {
              orderId: id
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    getProduct(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetById`, {
            params: {
              id,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
  
      })
    },
    addProduct(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/AddOrUpdate`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/Upload`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFileMultiple(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/MultiUpload/multiple`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
