<template>
  <section id="dashboard">
    <div
      id="loading-bg"
      v-if="isLoading"
      style="
        z-index: 999;
        opacity: 0.5;
        margin-top: -200px;
        margin-left: -100px;
      "
    >
      <div class="loading">
        <b-spinner
          style="width: 5rem; height: 5rem;"
          label="Large Spinner"
        ></b-spinner>
      </div>
    </div>
    <b-card no-body class="mb-0 p-2">
      <b-row>
        <b-col md="4">
          <b-form-group  label="เดือน/ปี" label-cols="4">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="filter-month-year"
              placeholder="Please Select"
              label="label"
              :options="optionMonthOfYear"
              v-model="selectedMonthOfYear"
            >
            </v-select>
          </b-form-group>
        </b-col>
        
      </b-row>
      <b-row v-if="optionsProductCode.length > 0 && selectedMonthOfYear != null">
        <b-col md="4">
          <b-form-group label="รหัสสินค้า" label-cols="4">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="invoice-filter-select"
              placeholder="Please Select"
              label="label"
              :options="optionsProductCode"
              v-model="selectedProductCode"
              multiple
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="optionsProductCode.length > 0 && selectedMonthOfYear != null">
        <b-col>
          <div class="d-flex">
                 <b-button variant="primary" @click="getReportUser()">
                   โหลดข้อมูล
                 </b-button>
               </div>
        </b-col>
      </b-row>
      <br />

      <!-- todo -->
      <div class="mx-2 mb-2" v-for="product in reportList" v-bind:key="product">
        <h3>{{ product.product_code }}</h3>
        <b-table
        ref="refProductListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="product.report_list"
        primary-key="id"
        show-empty
        :empty-text="'ไม่พบข้อมูล'"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <!-- <template #cell(index)="data">
         {{ (currentPage - 1) * perPage + data.index + 1 }}
       </template> -->
      </b-table>
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">
              แสดง -->
            <!-- {{ dataMeta.to }} -->
            <!-- จาก -->
            <!-- {{ dataMeta.of }} -->
            <!-- รายการ
            </span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <!-- <b-pagination
             v-model="currentPage"
             :total-rows="totalProducts"
             :per-page="perPage"
             first-number
             last-number
             class="mb-0 mt-1 mt-sm-0"
             prev-class="prev-item"
             next-class="next-item"
           >
             <template #prev-text>
               <feather-icon icon="ChevronLeftIcon" size="18" />
             </template>
             <template #next-text>
               <feather-icon icon="ChevronRightIcon" size="18" />
             </template>
           </b-pagination> -->
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BCol,
  BTable,
  BFormGroup,
  BFormTimepicker,
  BModal,
  VBModal,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import invoiceStoreModule from "./invoiceStoreModule";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export const HTTP = axios.create({
  baseURL: "https://api.teflowservice.com",
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    vSelect,
    BFormGroup,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    flatPickr,
    BFormTimepicker,
    BModal,
    VBModal,
    Ripple,
    BSpinner,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      data: null,
      optionMonthOfYear: [],
      selectedMonthOfYear: null,
      optionsProductCode: [],
      selectedProductCode: null,
      reportList: [],
    };
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "dashboardadmin";

    const tableColumns = [
      { key: "date", label: "วันที่" },
      
      {
        key: "agent",
        label: "ตัวแทนบริษัท",
      },
      {
        key: "agent_central",
        label: "ตัวแทนภาคกลาง",
      },
      {
        key: "agent_south",
        label: "ตัวแทนภาคใต้",
      },
    ];

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const optionsUser = ref([]);
    const optionsRole = ref([]);
    const selectedUser = ref(null);
    const selectedRole = ref(null);

    const isLoading = ref(false);

    const products = ref([]);
    store.dispatch("dashboardadmin/fetchSubinventorys").then((response) => {
      if (response !== null) {
        console.log("response", response);
      }
    });

    return {
      products,
      tableColumns,
      optionsUser,
      optionsRole,
      selectedUser,
      selectedRole,
      isLoading,
    };
  },
  created() {
    this.getMonthReport();
  },
  methods: {
    getMonthReport() {
      this.isLoading = true;
      HTTP
      .get("/api/Report/GetMonthReport")
      .then(
        (response) => {
          response.data.forEach((data) => {
            this.optionMonthOfYear.push({
              value: data.date,
              label: data.label,
            });
          });
          this.isLoading = false;
        }
      );
    },

    getProductCodeReport() {
      let monthOfYear = this.selectedMonthOfYear?.value || null;
      if(this.selectedMonthOfYear != null){
        this.isLoading = true;
        HTTP.get("api/Report/GetProductCodeReport?month=" + monthOfYear, {}).then( 
          (response) => {
            response.data.forEach((data) => {
              this.optionsProductCode.push({
                value: data.product_code,
                label: data.product_code,
              });
           this.isLoading = false;
          });
        }
      )}
    },

    getReportUser() {
      if( this.selectedProductCode.length == 0 || this.selectedMonthOfYear == null){
        this.reportList = [];
      }
      else{
      const payload = {
        month: this.selectedMonthOfYear?.value || null,
        product_code: this.selectedProductCode?.map((x) => x.value) || null,
      };
      this.isLoading = true;
      HTTP.post("/api/Report/GetReportUser", payload).then((response) => {
        this.reportList = response.data;
        this.isLoading = false;
      });
    }
},
  },
  watch: {
    selectedUser: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    selectedRole: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    startDate: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    endDate: {
      handler(newValue, oldValue) {
        this.getData();
      },
      deep: true,
    },
    selectedMonthOfYear:{
      handler(newValue, oldValue) {
        this.getProductCodeReport();
        this.selectedProductCode = [];
        this.reportList = [];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
