<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="nav align-items-center ml-auto pt-1"
      v-b-modal.modal-center-orderpreview
    >
      <h4>{{ headerPage }}</h4>
    </div>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      class="nav align-items-center ml-auto"
      v-show="!showSearchIcon"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userData.username}}
            </p>
            <span class="user-status">    {{userData.role}} | {{ userData.userType }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-navbar-nav
      class="nav align-items-center ml-auto"
      v-show="showSearchIcon"
    >
      <div
        class="nav align-items-center ml-auto"
        v-b-modal.modal-center-orderpreview
      >
        <feather-icon icon="SearchIcon" size="21" />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import router from "@/router";
import { ref, onUnmounted, computed, watch } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const showSearchIcon = ref(false);
    const headerPage = ref("");
    const { route, router } = useRouter();
    const routeParams = computed(() => route.value.name);
   
    switch (router.currentRoute.name) {
      case "history-order":
        headerPage.value = "ประวัติคำสั่งซื้อ";
        showSearchIcon.value = true;
        break;
      case "my-products":
        headerPage.value = "สินค้าของฉัน";
        showSearchIcon.value = false;
        break;
      case "open-bill":
        headerPage.value = "เปิดบิล";
        showSearchIcon.value = false;
        break;
      case "confirm-order":
        headerPage.value = "ลงออเดอร์";
        showSearchIcon.value = false;
        break;
      case "dashboard-ecommerce":
        headerPage.value = "หน้าหลัก";
        showSearchIcon.value = false;
        break;
      default:
        headerPage.value = "";
        showSearchIcon.value = false;
    }
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      switch (router.currentRoute.name) {
        case "history-order":
          headerPage.value = "ประวัติคำสั่งซื้อ";
          showSearchIcon.value = true;
          break;
        case "my-products":
          headerPage.value = "สินค้าของฉัน";
          showSearchIcon.value = false;
          break;
        case "open-bill":
          headerPage.value = "เปิดบิล";
          showSearchIcon.value = false;
          break;
        case "confirm-order":
          headerPage.value = "ลงออเดอร์";
          showSearchIcon.value = false;
          break;
        case "dashboard-ecommerce":
          headerPage.value = "หน้าหลัก";
          showSearchIcon.value = false;
          break;
        default:
          headerPage.value = "";
          showSearchIcon.value = false;
      }
    });

    const userData = getUserData()

    return { showSearchIcon, headerPage, userData };
  },

  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page

      this.$router.push({ name: "auth-login" });
      // if (router.currentRoute.path.substring(0,5) == '/shop') {
      //   this.$router.push({ name: "shopauth-login" });
      // } else {
      //   this.$router.push({ name: "adminauth-login" });
      // }
     
    },
  },
};
</script>
