<template>
  <e-commerce-checkout-step-cart />
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ref } from "@vue/composition-api";

import ECommerceCheckoutStepCart from "./ECommerceCheckoutStepCart.vue";

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    ECommerceCheckoutStepCart,
  },
  setup() {
    const refFormWizard = ref(null);
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab();
    };

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: "",
        mobile: "",
        houseNo: "",
        landmark: "",
        city: "",
        pincode: "",
        state: "",
        addressType: null,
      },
      payment: {
        cvv: "",
      },
    });

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
