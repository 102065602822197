import axios, { baseURL } from "@axios";
import { paginateArray, sortCompare } from "@/common";
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/invoice/invoices", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/invoice/clients")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    fetchOrders(ctx, queryParams) {
      const {
        q = "",
        perPage = 10000,
        page = 1,
        sortBy = "id",
        sortDesc = false,
        status = null,
        date = null,
        search = [],
        time = null,
        dateTo = null,
      } = queryParams;

      var dateFrom = null;


      var userSearch = [];
      search.forEach((item) => {
        userSearch.push(item.label);
      });

      const payload = {
        key: q,
        dateFrom: dateFrom,
        dateTo: dateTos,
        status: status,
        userList: userSearch,
      };
      return new Promise((resolve, reject) => {

        if(dateFrom != null && dateTo!= null){

          axios // GetOrderByStatusSalesOrder // GetOrderPrintedBySalesOrder
          .post(`${baseURL}/api/Order/GetOrderPrintedBySalesOrder`, payload)
          .then((response) => {

            var result = response.data.data;

            resolve({
              data: paginateArray(result, 10000, page),
              total: response.data.totalCount,
            });
            
          })
          
          .catch((error) => reject(error));

        }
        else{
          resolve({
            data: paginateArray([], 10000, page),
            total: 0,
          });
        }




      });
    },


    fetchPrintedOrders(ctx, queryParams) {
      const {
        q = "",
        perPage = 10000,
        page = 1,
        sortBy = "id",
        sortDesc = false,
        status = null,
        date = null,
        search = [],
        time = null,
        dateTo = null,
      } = queryParams;
    
      var dateFrom = null;
    
      if (date) {
        var dateFrom = new Date(date);
        dateFrom.setHours(dateFrom.getHours() + 7);
      }
      // else{
      //   var dateFrom = new Date();
      //   dateFrom.setUTCHours(0,0,0,0);
      // }
    
      var dateTos = null;
    
      if (dateTo) {
        var dateTos = new Date(dateTo);
        dateTos.setHours(dateTos.getHours() + 7);
      }
      // else{
      //   var dateTos = new Date();
      //   dateTos.setUTCHours(23,59,59,999);
      // }
      // if (date && date.length > 11) {
      //   dateFrom = new Date(date.substring(0, 10));
      //   dateTo = new Date(date.substring(14, 24));
      //   if (time) {
      //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //     dateTo = new Date(date.substring(14, 18),Number(date.substring(19, 21)) - 1,date.substring(22, 24), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //   }
      // } else if (date && date.length <= 11) {
      //   dateFrom = new Date(date.substring(0, 10));
      //   if (time) {
      //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
      //   }
      // }
    
      var userSearch = [];
      search.forEach((item) => {
        userSearch.push(item.label);
      });
    
      const payload = {
        key: q,
        dateFrom: dateFrom,
        dateTo: dateTos,
        status: status,
        userList: userSearch,
      };
      return new Promise((resolve, reject) => {

        console.log("dateFrom != null && dateTo!= null", dateFrom != null && dateTos!= null);

        if(dateFrom != null && dateTos!= null){
        
        axios 
          .post(`${baseURL}/api/Order/GetOrderPrintedBySalesOrderV2`, payload)
          .then((response) => {

            var result = response.data.data;
            resolve({
              data: paginateArray(result, 10000, page),
              total: response.data.totalCount,
            });
            
          })
          
          .catch((error) => reject(error));
        }
        else{
          resolve({
            data: paginateArray([], 10000, page),
            total: 0,
          });
        }

      });
    },
    
    
        fetchWaitOrders(ctx, queryParams) {
          const {
            q = "",
            perPage = 10000,
            page = 1,
            sortBy = "id",
            sortDesc = false,
            status = null,
            date = null,
            search = [],
            time = null,
            dateTo = null,
          } = queryParams;

          console.log('queryParams', queryParams);
    
          var dateFrom = null;
    
          if (date) {
            var dateFrom = new Date(date);
            dateFrom.setHours(dateFrom.getHours() + 7);
          }
          // else{
          //   var dateFrom = new Date();
          //   dateFrom.setUTCHours(0,0,0,0);
          // }
    
          var dateTos = null;
    
          if (dateTo) {
            var dateTos = new Date(dateTo);
            dateTos.setHours(dateTos.getHours() + 7);
          }
          // else{
          //   var dateTos = new Date();
          //   dateTos.setUTCHours(23,59,59,999);
          // }
          // if (date && date.length > 11) {
          //   dateFrom = new Date(date.substring(0, 10));
          //   dateTo = new Date(date.substring(14, 24));
          //   if (time) {
          //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
          //     dateTo = new Date(date.substring(14, 18),Number(date.substring(19, 21)) - 1,date.substring(22, 24), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
          //   }
          // } else if (date && date.length <= 11) {
          //   dateFrom = new Date(date.substring(0, 10));
          //   if (time) {
          //     dateFrom = new Date(date.substring(0, 4),Number(date.substring(5, 7)) - 1,date.substring(8, 10), Number(time.substring(0, 2)) - 5, time.substring(3, 5));
          //   }
          // }
    
          var userSearch = [];
          search.forEach((item) => {
            userSearch.push(item.label);
          });
    
          const payload = {
            key: q,
            dateFrom: dateFrom,
            dateTo: dateTos,
            status: status,
            userList: userSearch,
          };
          return new Promise((resolve, reject) => {
            axios 
              .post(`${baseURL}/api/Order/GetOrderWaitingPrintBySalesOrderV2`, payload)
              .then((response) => {
                /*
                 key: "index", label: "ลำดับ" },
        { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
        {
          key: "createdDateText",
          label: "วันที่สั่งซื้อ",
         
        },
        { key: "contactName", label: "ชื่อ - นามสกุล" },
        { key: "contactMobile", label: "เบอร์โทรศัพท์" },
        {
          key: "totalPriceStd",
          label: "ยอดคำสั่งซื้อ (บาท)",
          formatter: (val) => `${val.toLocaleString("en-US")}`,
        }, // ไม่มี
        {
          key: "orderStatus",
          label: "สถานะคำสั่งซื้อ",
          formatter: (val) => changeText(val),
        }, // ไม่มี 
        { key: "userName", label: "ชื่อผู้ใช้งาน" },
        { key: "userFullName", label: "ชื่อตัวแทน" }, // ไม่มี
        { key: "isPrinted", label: "สถานะปริ๊น" }, 
                */
    
                var result = response.data.data
    
                /*
                var result = response.data.data.map((x) => {
                  return {
                    "orderNo": x.order_no,
                    "createdDateText": new Date(x.created_date).toLocaleString('en-GB', { timeZone: 'UTC' }),
                    "contactName": x.order_fullname,
                    "contactMobile": x.order_mobile,
                    "totalPriceStd": x.total_price_std,
                    "orderStatus": x.order_status,
                    "userName": x.user_id.username,
                    "userFullName": x.user_id.fullname,
                    "isPrinted": x.isPrinted,
                  }
                });
                */
    
                resolve({
                  data: paginateArray(result, 10000, page),
                  total: response.data.totalCount,
                });
                
              })
              
              .catch((error) => reject(error));
          });
        },

    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/GetOrderSalesOrderById?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/Update?orderId=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserList(ctx) {
      return new Promise((resolve, reject) => {
        const payload = {
          key: "",
          dateFrom: null,
          dateTo: null,
          status: null,
        };
        axios
          .post(`${baseURL}/api/User/GetByKey`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    UpdateOrderPrinted(ctx, payload) {

      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Order/UpdateOrderPrinted`, payload, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


  },
};
