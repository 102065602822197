<template>
  <div class="checkout-items">
    <b-card
      v-for="product in data.products"
      :key="product.id"
      class="ecommerce-card"
      no-body
      style="display: flex"
    >
      <b-row class="px-50">
        <b-col cols="1" style="display: flex; align-items: center;">
          <!-- Radio -->
          <b-form-checkbox v-model="data.selectedProducts" :value="product">
          </b-form-checkbox>
        </b-col>
        <b-col cols="4" >
          <!-- Product Image -->
          <div class="item-img">
            <b-link>
              <b-img
                :src="product.product_Image_Id.url"
                :alt="`${product.name}-${product.id}`"
                style="width: 8rem;height: 8rem;"
              />
            </b-link>
          </div>
        </b-col>
        <b-col cols="7">
          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body">
                  {{ product.productName }}
                </b-link>
              </h6>
              <span class="item-company" style="font-size: 1.25rem"
                >ราคา
                <b-link class="company-name">{{ product.price.toLocaleString("en-US") }}</b-link></span
              >
            </div>
            <span class="text-success mb-1" style="font-weight: 200">มีสินค้า 500 ชิ้น</span>
            <div class="item-quantity">
              <span class="quantity-title">Qty:</span>
              <b-form-input
                v-model="product.qty"
                size="sm"
                class="ml-75"
                inline
                type="number"
              />
            </div>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import store from "@/store";
import { ref } from "@vue/composition-api";
import { formatDate } from "@core/utils/filter";
import { useEcommerce, useEcommerceUi } from "../useEcommerce";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  setup() {
    const products = ref([]);

    const { toggleProductInWishlist } = useEcommerceUi();
    const { removeProductFromCart } = useEcommerce();
    const removeProductFromCartClick = (product) => {
      removeProductFromCart(product.id).then(() => {
        const productIndex = products.value.findIndex(
          (p) => p.id === product.id
        );
        products.value.splice(productIndex, 1);

        store.commit(
          "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
          products.value.length
        );
      });
    };

    const fetchCartProducts = () => {
      store.dispatch("app-ecommerce/fetchCartProducts").then((response) => {
        products.value = response.data.products;

      });
    };
    fetchCartProducts();

    return {
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,

      // Filter
      formatDate,
    };
  },
};
</script>

<style></style>
